import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import type { ModalChildProps } from './Modal.interface';

import styles from './scss/modal.module.scss';

/**
 * @deprecated Use `@/core/tamagoshiTailwind/components/Modal/Modal` instead.
 */
export const ModalHeader: FunctionComponent<ModalChildProps> = ({
  className,
  children,
  tag: Tag = 'header',
}) => <Tag className={classnames(styles.header, className)}>{children}</Tag>;

ModalHeader.displayName = 'ModalHeader';
