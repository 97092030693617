import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

// TODO Use variable when it's avaialble on next.js

export interface MediaQueryContext {
  isSizeBelow: (point: Query) => boolean;
  isSizeAbove: (point: Query) => boolean;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  /* @deprecated use of these breakpoints instead (xs, sm, md, lg, xl) */
  [key: string]: unknown;
}

type MediaQueryLists = {
  [key in Query]?: MediaQueryList;
};

type Matches = {
  [key in Query]?: boolean;
};

export interface BreakpointProviderProps extends PropsWithChildren {
  queries?: Record<Query, string>;
}

const defaultQueries = {
  xs: `screen and (max-width: 359px)`,
  sm: `screen and (min-width: 360px) and (max-width: 599px)`,
  md: `screen and (min-width: 600px) and (max-width: 1023px)`,
  lg: `screen and (min-width: 1024px) and (max-width: 1439px)`,
  xl: `screen and (min-width: 1440px)`,
} as const;

// eslint-disable-next-line @typescript-eslint/ban-types
type Query = keyof typeof defaultQueries | (string & {});

const defaultValue = {
  isSizeBelow: (_point: string) => false,
  isSizeAbove: (_point: string) => false,
};

export const BREAKPOINTS = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const;

const arrayOfPreferences = [
  BREAKPOINTS.xs,
  BREAKPOINTS.sm,
  BREAKPOINTS.md,
  BREAKPOINTS.lg,
  BREAKPOINTS.xl,
] as const;

const findBreakpointIndex = (point: string) =>
  // eslint-disable-next-line unicorn/prefer-array-index-of
  arrayOfPreferences.findIndex((el) => el === point);

const findActualBreakpointIndex = (queryMatch: Matches) =>
  // eslint-disable-next-line unicorn/prefer-native-coercion-functions
  arrayOfPreferences.map((point) => queryMatch[point]).findIndex((el) => el);

const BreakpointContext = createContext<MediaQueryContext>(defaultValue);

export const BreakpointProvider = ({
  children,
  queries = defaultQueries,
}: BreakpointProviderProps) => {
  const [queryMatch, setQueryMatch] = useState<Matches>({});

  useEffect(() => {
    const mediaQueryLists: MediaQueryLists = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches: Matches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media]?.matches
        );
        return acc;
      }, {} as Matches);
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: Matches = {};
      keys.forEach((media: string) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media]?.matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string' && media !== undefined) {
          mediaQueryLists[media]?.addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media]?.removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  const isSizeBelow = useCallback(
    (point: string) => {
      const indexOfPoint = findBreakpointIndex(point);
      if (indexOfPoint <= 0) return false;
      const actualBreakpointIndex = findActualBreakpointIndex(queryMatch);
      if (actualBreakpointIndex === -1) return false;
      return actualBreakpointIndex < indexOfPoint;
    },
    [queryMatch],
  );

  const isSizeAbove = useCallback(
    (point: string) => {
      const indexOfPoint = findBreakpointIndex(point);
      if (indexOfPoint < 0 || indexOfPoint >= arrayOfPreferences.length - 1)
        return false;
      return findActualBreakpointIndex(queryMatch) > indexOfPoint;
    },
    [queryMatch],
  );

  const queryVitaminated = useMemo(
    () => ({ ...queryMatch, isSizeAbove, isSizeBelow }),
    [queryMatch, isSizeAbove, isSizeBelow],
  );

  return (
    <BreakpointContext.Provider value={queryVitaminated}>
      {children}
    </BreakpointContext.Provider>
  );
};

export const useBreakpoints = () => {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoints must be used within BreakpointProvider');
  }
  return context;
};

BreakpointProvider.displayName = 'BreakpointProvider';
