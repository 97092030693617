import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Leaf: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemLeaf" viewBox="0 0 12 12" {...props}>
    <path
      d="M2.03464 3.3676C0.21464 5.18823 0.21464 8.13592 2.02131 9.95656C3.00131 7.6891 4.74797 5.79511 6.92797 4.66805C5.08131 6.22859 3.78797 8.40935 3.33464 10.8835C5.06797 11.7038 7.2013 11.4037 8.63464 9.96989C10.9546 7.64909 11.3346 0.666656 11.3346 0.666656C11.3346 0.666656 4.35464 1.04679 2.03464 3.3676Z"
      fill="#21785B"
    />
  </Svg>
);

Leaf.displayName = 'Leaf';
