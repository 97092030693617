import type { SpartacuxCoreState } from '../core.state';

export const statusCodeSelector = (state: SpartacuxCoreState) =>
  state.core.page?.statusCode;

export const pageTypeSelector = (state: SpartacuxCoreState) =>
  state.core.page?.pageType;

export const pageNameSelector = (state: SpartacuxCoreState) =>
  state.core.page?.pageName;
