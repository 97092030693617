import { forwardRef, type ElementRef } from 'react';

import { Link } from '@/core/routing/components/Link';
import {
  GenericButton,
  type GenericButtonProps,
} from '@/core/tamagoshiv2/components/Button/GenericButton';

export type NavigationButtonProps = GenericButtonProps<typeof Link>;

/**
 * A link rendered as a button implementing the Design System.
 * Not to be confused with a button that looks like a link. This is a link that
 * looks like a button. If you need a link that looks like a link well... use a
 * link directly.
 *
 * See the related [Figma][figma] for more details.
 *
 * [figma]: https://www.figma.com/file/KAiRlBccr6bFYmKby9wQrR?node-id=11045-34426
 */
export const NavigationButton = forwardRef<
  ElementRef<typeof Link>,
  NavigationButtonProps
>((props, ref) => <GenericButton asComponent={Link} ref={ref} {...props} />);

NavigationButton.displayName = 'NavigationButton';
