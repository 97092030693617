import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';
import type { MarketDetail } from '@/domains/productDiscovery/ProductPage/interfaces/Detail.model';
import {
  detailSelector,
  fetchingBasicDetailSelector,
} from '@/domains/productDiscovery/ProductPage/selectors/detail';

import type { Market } from '../interfaces/Market';

export const useDetail = <
  M extends Market = 'b2c' | 'b2b',
>(): MarketDetail<M> =>
  useSelector<
    SpartacuxProductDiscoveryDomainState,
    MarketDetail<M> | undefined
  >(detailSelector) ?? ({} as MarketDetail<M>);

export const useFetchingBasicDetail = (): boolean | undefined =>
  useSelector<SpartacuxProductDiscoveryDomainState, boolean | undefined>(
    fetchingBasicDetailSelector,
  ) || undefined;
