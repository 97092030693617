import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';
import { useEffect, useRef, type FunctionComponent } from 'react';

import type { ModalChildProps } from './Modal.interface';

import styles from './scss/modalContent.module.scss';

/**
 * @deprecated Use `@/core/tamagoshiTailwind/components/Modal/Modal` instead.
 */
export const ModalContent: FunctionComponent<ModalChildProps> = ({
  className,
  children,
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalContentRef.current !== null) {
      const modalContentElement = modalContentRef.current;
      disableBodyScroll(modalContentElement);
      return () => enableBodyScroll(modalContentElement);
    }
  }, []);

  return (
    <div
      ref={modalContentRef}
      className={classnames(styles.content, className)}
    >
      {children}
    </div>
  );
};

ModalContent.displayName = 'ModalContent';
