import { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';
import {
  MarketDetail,
  ProductPageError,
} from '@/domains/productDiscovery/ProductPage/interfaces/Detail.model';
import { Market } from '@/domains/productDiscovery/ProductPage/interfaces/Market';

export const detailSelector = <M extends Market = 'b2c' | 'b2b'>(
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): MarketDetail<M> | undefined =>
  state.productDiscovery?.productPage?.detail?.data as
    | MarketDetail<M>
    | undefined;

export const detailErrorSelector = (
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): ProductPageError | undefined =>
  state.productDiscovery?.productPage?.detail?.error;

export const fetchingBasicDetailSelector = (
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): boolean | undefined =>
  state.productDiscovery?.productPage?.detail?.fetchingBasicDetail;

export const smartQuerySelector = (
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): string | undefined =>
  state.productDiscovery?.productPage?.detail?.data?.smartQuery;
