import { createSelector } from 'reselect';

import type { SpartacuxCustomerManagementState } from '@/domains/customerManagement/customerManagement.model';

import type {
  CustomerProductPreferencesState,
  PurchasedProduct,
} from './model';

export interface CustomerProductPreferencesSelector {
  isLoading: boolean;
  purchasedTwice: Set<number>;
  purchasedAtLeastOnceEver: PurchasedProduct[];
  error?: string;
}

export const customerProductPreferencesSelector = createSelector(
  (
    state: SpartacuxCustomerManagementState,
  ): CustomerProductPreferencesState | undefined =>
    state.customerManagement?.customerProductPreferences,
  (
    customerProductPreferencesState?: CustomerProductPreferencesState,
  ): CustomerProductPreferencesSelector => ({
    isLoading: Boolean(customerProductPreferencesState?.fetching),
    purchasedTwice: new Set(
      customerProductPreferencesState?.data?.purchasedTwice,
    ),
    purchasedAtLeastOnceEver:
      customerProductPreferencesState?.data?.purchasedAtLeastOnceEver || [],
    error: customerProductPreferencesState?.error,
  }),
);
